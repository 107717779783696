<template>
  <NuxtLayout>
    <section class="mb-32 mt-48">
      <div class="container">
        <div class="grid grid-cols-12">
          <div class="col-start-2 col-end-12 text-center">
            <div class="font-heading text-9xl font-black text-primary">
              {{ error.statusCode }}
            </div>
            <div class="mb-8 text-3xl font-bold">
              <template v-if="error.statusCode === 404">
                Page not found
              </template>
              <template v-else>
                {{ error.message }}
              </template>
            </div>
            <UiButton
              label="Back to home"
              @click="handleError"
            >
              Terug naar home
            </UiButton>
            <div />
          </div>
        </div>
      </div>
    </section>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
