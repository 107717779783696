<script setup>
useHead(() => ({
  script: [
    {
      tagPosition: 'head',
      'data-cookieconsent': 'ignore',
      innerHTML: `
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          dataLayer.push(arguments);
        }

        gtag('consent', 'default', {
          ad_personalization: 'denied',
          ad_storage: 'denied',
          ad_user_data: 'denied',
          analytics_storage: 'denied',
          functionality_storage: 'denied',
          personalization_storage: 'denied',
          security_storage: 'granted',
          wait_for_update: 500
        });

        gtag('set', 'ads_data_redaction', true);
        gtag('set', 'url_passthrough', true);
      `,
    },
    {
      id: 'Cookiebot',
      src: 'https://consent.cookiebot.com/uc.js',
      'data-cbid': 'b25629d4-61ea-448b-b5df-5faea24c0ce2',
      'data-blockingmode': 'auto',
    },
  ],
}));
</script>
<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
