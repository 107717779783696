export function downloadFile(url : string) {
  fetch(url, {method: 'get', referrerPolicy: 'no-referrer'})
    .then((res) => {
      const disposition = res.headers.get('Content-Disposition');
      let fileName : string = '';
      if (disposition && disposition.includes('attachment')) {
        const fileNameMatch = disposition.match(/filename="(.+)"/);
        if (fileNameMatch?.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
      if (!fileName) {
        fileName = url.split('/').pop() || 'download';
      }
      return res.blob().then(blob => ({blob, fileName}));
    })
    .then(({blob, fileName}) => {
      const aElement = document.createElement('a');
      aElement.setAttribute('download', fileName);
      const href = URL.createObjectURL(blob);
      aElement.href = href;
      aElement.setAttribute('target', '_blank');
      aElement.click();
      URL.revokeObjectURL(href);
    });
}
