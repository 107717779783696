import { defineNuxtPlugin } from '#app/nuxt'
import { LazyArrowTopRight, LazyCheck, LazyChevronDown, LazyChevronLeft, LazyFacebook, LazyHamburger, LazyLinkedin, LazyXMark, LazyYoutube, LazyElementButton, LazyElementCardContent, LazyElementCardLink, LazyElementCatalogue, LazyElementContactBlock, LazyElementContactBlocks, LazyElementForm, LazyElementImage, LazyElementLink, LazyElementNewsOverview, LazyElementStatistic, LazyElementStatistics, LazyElementText, LazyElementThreeImages, LazyElementTitle, LazyElementVacancyOverview, LazyElementVacancyOverviewCountryFilter, LazyElementVideo, LazyLayoutOneColumn, LazyLayoutThreeColumns, LazyLayoutTwoColumns, LazyPage, LazyPageNews, LazyPageVacancy, LazySection, LazySectionHeroVideo, LazySectionText, LazySectionTextImage, LazySectionTextblockSlider, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["ArrowTopRight", LazyArrowTopRight],
["Check", LazyCheck],
["ChevronDown", LazyChevronDown],
["ChevronLeft", LazyChevronLeft],
["Facebook", LazyFacebook],
["Hamburger", LazyHamburger],
["Linkedin", LazyLinkedin],
["XMark", LazyXMark],
["Youtube", LazyYoutube],
["ElementButton", LazyElementButton],
["ElementCardContent", LazyElementCardContent],
["ElementCardLink", LazyElementCardLink],
["ElementCatalogue", LazyElementCatalogue],
["ElementContactBlock", LazyElementContactBlock],
["ElementContactBlocks", LazyElementContactBlocks],
["ElementForm", LazyElementForm],
["ElementImage", LazyElementImage],
["ElementLink", LazyElementLink],
["ElementNewsOverview", LazyElementNewsOverview],
["ElementStatistic", LazyElementStatistic],
["ElementStatistics", LazyElementStatistics],
["ElementText", LazyElementText],
["ElementThreeImages", LazyElementThreeImages],
["ElementTitle", LazyElementTitle],
["ElementVacancyOverview", LazyElementVacancyOverview],
["ElementVacancyOverviewCountryFilter", LazyElementVacancyOverviewCountryFilter],
["ElementVideo", LazyElementVideo],
["LayoutOneColumn", LazyLayoutOneColumn],
["LayoutThreeColumns", LazyLayoutThreeColumns],
["LayoutTwoColumns", LazyLayoutTwoColumns],
["Page", LazyPage],
["PageNews", LazyPageNews],
["PageVacancy", LazyPageVacancy],
["Section", LazySection],
["SectionHeroVideo", LazySectionHeroVideo],
["SectionText", LazySectionText],
["SectionTextImage", LazySectionTextImage],
["SectionTextblockSlider", LazySectionTextblockSlider],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
