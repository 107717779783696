import validate from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45trailing_45slash_45global from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_esbuild@0.17.19_magicast@0.3.5_nuxt@3.1_4jtwim2rp6qukxe3c4fbbppvju/node_modules/@bttr-devs/nuxt-layer-base/middleware/redirect-trailing-slash.global.ts";
import storyblok_45preview_45global from "/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-storyblok@1.6.2_@bugsnag+core@7.25.0_esbuild@0.17.19_ioredis@5.4.1_magi_the4zluonovc2lv57txzsx4bdy/node_modules/@bttr-devs/nuxt-layer-storyblok/middleware/storyblok-preview.global.ts";
import manifest_45route_45rule from "/vercel/path0/nuxt/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_@vercel+kv@1.0.1_encoding@0.1.13_eslint@_53ry7ul7eosd7wglgzbnswff44/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45trailing_45slash_45global,
  storyblok_45preview_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "storyblok-page": () => import("/vercel/path0/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-storyblok@1.6.2_@bugsnag+core@7.25.0_esbuild@0.17.19_ioredis@5.4.1_magi_the4zluonovc2lv57txzsx4bdy/node_modules/@bttr-devs/nuxt-layer-storyblok/middleware/storyblok-page.ts")
}